import axios from 'axios';
import { API_HOSTNAME } from '../types/consts';
import { LOADING } from '../types/covidTypes';

export const getScoreCovidAction = (data, enqueueSnackbar) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const response = await axios.post(`${API_HOSTNAME}/api/Covid`, data);
    if (response.status === 200) {
      enqueueSnackbar(response.data.message, {
        variant: response.data.type,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      setTimeout(() => { window.location.href = response.data.url; }, 4000);
    }
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.error(error);
  }
};

export const test = () => async () => {
};
