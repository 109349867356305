/* eslint-disable import/prefer-default-export */
export const Rules = {
  email: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^(?!.*?\.\.)([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/,
      message: 'Debe ingresar una dirección de correo válida',
    },
  },
  letrasTildes: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[a-zA-Z\u00C0-\u00FF ]*$/,
      message: 'Únicamente es permitido letras y tildes',
    },
  },
  numerico: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[0-9]*$/,
      message: 'El campo debe ser numérico.',
    },
  },
  letrasNumeros: {
    required: 'Campo obligatorio',
    pattern: {
      value: /^[a-zA-Z0-9]*$/,
      message: 'El campo debe ser numérico.',
    },
  },
  required: {
    required: 'Campo obligatorio',
  },
};
