import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Covid19 from '../views/Covid19';

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/Covid/:idRunt/:idProject/:okUrl/:badUrl" component={Covid19} />
    </Switch>
  </BrowserRouter>
);

export default App;
