/* eslint-disable no-plusplus */
/* eslint-disable no-prototype-builtins */
import React from 'react';
import { connect } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Loading from '../../components/Loading';

import { Rules } from '../../helpers/RHFRules';
import { getScoreCovidAction } from '../../redux/actions/covidAction';
import background from '../../assets/img/covidBg.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
  },
  titleWrapper: {
    marginBottom: '5px',
    color: '#BF103A',
  },
  title: {
    fontWeight: 'bold',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  formErrors: {
    color: 'red',
    fontSize: '10px',
  },
  submit: {
    margin: theme.spacing(2, 0, 1),
    color: '#FFFFFF',
    backgroundColor: '#BF103A',
    padding: '10px',
  },
}));

const question = [
  { id: 'P1', message: '¿Ha presentado fiebre mayor a 38º centígrados?' },
  { id: 'P2', message: '¿Ha sufrido episodios de tos seca?' },
  { id: 'P3', message: '¿Siente malestar general, agotamiento y síntomas gripales?' },
  { id: 'P4', message: '¿Ha presentado náuseas y diarrea?' },
  { id: 'P5', message: '¿Ha sentido dolor de garganta?' },
  { id: 'P6', message: '¿Ha tenido dolor de cabeza?' },
];

const tipoDocumento = [
  { value: 'CC', label: 'Cédula de Ciudadanía' },
  { value: 'CE', label: 'Cédula de Extranjería' },
  { value: 'PA', label: 'Pasaporte' },
  { value: 'TI', label: 'Tarjeta de Identidad' },
  { value: 'CD', label: 'Carné Diplomático' },
];

const Survey = (props) => {
  const {
    getScoreCovid, loading,
    match: {
      params: {
        idRunt, idProject, okUrl, badUrl,
      },
    },
  } = props;
  const classes = useStyles();
  const {
    handleSubmit, errors, register, control, watch,
  } = useForm();

  const watchTipoDocumento = watch('tipoDocumento');

  const { enqueueSnackbar } = useSnackbar();


  const onSubmit = (formData) => {
    const params = {
      idRunt, idProject, okUrl, badUrl,
    };

    const survey = {
      P1: formData.P1,
      P2: formData.P2,
      P3: formData.P3,
      P4: formData.P4,
      P5: formData.P5,
      P6: formData.P6,
      otro: formData.otros,
    };

    const personal = {
      departamento: formData.departamento,
      municipio: formData.municipio,
      nombres: formData.nombres,
      apellidos: formData.apellidos,
      tipoDocumento: formData.tipoDocumento,
      documento: formData.documento,
      email: formData.email,
      celular: formData.celular,
    };

    const data = {
      params, personal, survey,
    };
    getScoreCovid(data, enqueueSnackbar);
  };

  function hasAllQuestions(obj, q) {
    const qId = q.map((x) => x.id);
    for (let i = 0; i < qId.length; i++) {
      if (obj.hasOwnProperty(qId[i])) return true;
    }
    return false;
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        {
          loading ? <Loading /> : (
            <Box className={classes.paper}>
              <Box className={classes.titleWrapper}>
                <Typography className={classes.title}>
                  Encuesta de medición de riesgo de contagio por COVID19
                </Typography>
              </Box>
              <form
                className={classes.form}
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  label="Departamento"
                  name="departamento"
                  autoComplete="off"
                  error={Boolean(errors.departamento)}
                  inputRef={register(Rules.letrasTildes)}
                />
                {errors.departamento && (
                  <Typography className={classes.formErrors}>
                    {errors.departamento.message}
                  </Typography>
                )}
                <TextField
                  size="small"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  label="Municipio"
                  name="municipio"
                  autoComplete="off"
                  error={Boolean(errors.municipio)}
                  inputRef={register(Rules.letrasTildes)}
                />
                {errors.municipio && (
                  <Typography className={classes.formErrors}>
                    {errors.municipio.message}
                  </Typography>
                )}

                <TextField
                  size="small"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  label="Nombres"
                  name="nombres"
                  autoComplete="off"
                  error={Boolean(errors.nombres)}
                  inputRef={register(Rules.letrasTildes)}
                />
                {errors.nombres && (
                  <Typography className={classes.formErrors}>
                    {errors.nombres.message}
                  </Typography>
                )}

                <TextField
                  size="small"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  label="Apellidos"
                  name="apellidos"
                  autoComplete="off"
                  error={Boolean(errors.apellidos)}
                  inputRef={register(Rules.letrasTildes)}
                />
                {errors.apellidos && (
                  <Typography className={classes.formErrors}>
                    {errors.apellidos.message}
                  </Typography>
                )}

                <Controller
                  as={(
                    <TextField
                      select
                      label="Tipo documento"
                      size="small"
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                    >
                      {tipoDocumento.map(
                        (td) => <MenuItem key={td.value} value={td.value}>{td.label}</MenuItem>,
                      )}
                    </TextField>
                  )}
                  name="tipoDocumento"
                  control={control}
                  rules={Rules.required}
                  error={Boolean(errors.tipoDocumento)}
                  defaultValue=""
                />
                {errors.tipoDocumento && (
                  <Typography className={classes.formErrors}>
                    {errors.tipoDocumento.message}
                  </Typography>
                )}

                <TextField
                  size="small"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  label="Documento de identidad"
                  name="documento"
                  autoComplete="off"
                  error={Boolean(errors.documento)}
                  inputRef={
                    watchTipoDocumento === 'PA' || watchTipoDocumento === 'CD'
                      ? register(Rules.letrasNumeros) : register(Rules.numerico)
                  }
                />
                {errors.documento && (
                <Typography className={classes.formErrors}>
                  {errors.documento.message}
                </Typography>
                )}

                <TextField
                  size="small"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  label="Correo electrónico"
                  name="email"
                  autoComplete="off"
                  error={Boolean(errors.email)}
                  inputRef={register(Rules.email)}
                />
                {errors.email && (
                <Typography className={classes.formErrors}>
                  {errors.email.message}
                </Typography>
                )}

                <TextField
                  size="small"
                  variant="outlined"
                  margin="dense"
                  required
                  fullWidth
                  label="Celular"
                  name="celular"
                  autoComplete="off"
                  error={Boolean(errors.celular)}
                  inputRef={register(Rules.numerico)}
                />
                {errors.celular && (
                <Typography className={classes.formErrors}>
                  {errors.celular.message}
                </Typography>
                )}

                <Typography>
                  Indique si usted ha presentado alguno de los síntomas enunciados a
                  continuación durante los últimos cinco días:
                </Typography>

                <List dense disablePadding>
                  {
                    question.map(
                      (q) => (
                        <ListItem
                          key={q.id}
                          divider
                          dense
                          disableGutters
                        >
                          <ListItemText primary={q.message} />
                          <Controller
                            as={(
                              <RadioGroup
                                style={{ flexDirection: 'row' }}
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<Radio required size="small" />}
                                  label="Si"
                                  labelPlacement="start"
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<Radio required size="small" />}
                                  label="No"
                                  labelPlacement="start"
                                />
                              </RadioGroup>
                            )}
                            name={q.id}
                            control={control}
                            rules={Rules.required}
                            defaultValue=""
                          />
                        </ListItem>
                      ),
                    )
                  }
                </List>
                {hasAllQuestions(errors, question) && (
                <Typography className={classes.formErrors}>
                  Todas las preguntas son requeridas
                </Typography>
                )}

                <TextField
                  size="small"
                  variant="outlined"
                  margin="dense"
                  multiline
                  rows={3}
                  fullWidth
                  placeholder="¿Existe otro aspecto que haya incidido en su condición de salud durante los últimos días?"
                  name="otros"
                  autoComplete="off"
                  inputRef={register}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Enviar
                </Button>
              </form>

            </Box>
          )
         }
      </Grid>
    </Grid>
  );
};

const mapStateToProps = ({ covid }) => ({
  loading: covid.loading,
});

const mapDispatchToProps = ({
  getScoreCovid: getScoreCovidAction,
});

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
